<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body>
            <b-card-header>
                <div>
                    <b-card-title>{{ isShowChart ? 'Devolución Por Grupos' : 'Detalle Devoluciones Por Grupos' }}</b-card-title>
                    <b-card-text class="font-small-2 text-capitalize">
                        {{ selectedMonth }} {{ isLastYear ? lastYear : currentYear }}
                    </b-card-text>
                </div>

                <b-dropdown variant="link" no-caret class="chart-dropdown" dropright toggle-class="p-0">
                    <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="22" class="text-body cursor-pointer"/>
                    </template>
                    <b-dropdown-item href="#" @click="changeView">
                        <span v-text="isShowChart ? ' Ver detalles' : ' Ver gráfica'" />
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click="setLastYear">
                       Ver Año {{ isLastYear ? currentYear : lastYear }}
                    </b-dropdown-item>
                </b-dropdown>
            </b-card-header>
            <div v-show="groups.length > 0">            
                <vue-apex-charts v-if="isShowChart && !loading && groups.length > 0" ref="donutChart" type="donut" :options="donutChart.chartOptions" :series="donutChart.series" />                
                <div v-if="!isShowChart" ref="tableContainer" class="mb-0_ mt-1 table-responsive b-table-sticky-header">
                    <table class="table table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small">
                        <thead>
                        <tr>
                            <th>Grupo</th>
                            <th>Importe</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in groups" :key="index">
                                <td style="font-size: 10px;">{{ item.grupo }}</td>
                                <td style="font-size: 10px;">{{ formatCurrency(item.venta_Importe) }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td class="font-weight-bold text-right">TOTAL</td>
                                <td class="font-weight-bold">{{ formatCurrency(groups.reduce((carry, item) => carry + item.venta_Importe, 0)) }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div v-if="groups.length <= 0">
                <h5 class="text-center">No hay datos para mostrar para el año {{ isLastYear ? lastYear : currentYear }}</h5>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>

import moment from 'moment'
import {
    BCard, BCardTitle, BImg, BAvatar, BCardText, BCardHeader, BOverlay, BButton,
    BDropdown, BDropdownItem, BCardFooter, BCardBody, BRow, BCol
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { kFormatter } from '@core/utils/filter'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import FeatherIcon from '../feather-icon/FeatherIcon.vue'

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
    name: 'SalesReturnArticlesGroups',
    components: {
        BCard,
        BCardTitle,
        BCardHeader,
        BAvatar,
        BImg,
        BCardText,
        BOverlay,
        BButton,
        BDropdown,
        BDropdownItem,
        BCardFooter,
        BCardBody,
        BRow,
        BCol,
        VueApexCharts,

        VuePerfectScrollbar,
        BCardActions,
        FeatherIcon,
    },
    props: {
        refresh: {
            type: Boolean,
            default: () => false,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            groups: [],
            donutChart: {
                series: [],
                chartOptions: {
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontFamily: 'Montserrat',
                    },
                    colors: [
                        $themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger
                    ],
                    dataLabels: {
                        enabled: true,
                        fontFamily: 'Montserrat',
                        formatter(val) {
                            return `${parseInt(val)}%`
                        },
                    },
                    tooltip: {
                        enabled: true, // Habilita el tooltip                        
                        y: {
                            formatter(val) {
                                return kFormatter(val);
                            }
                        }
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        fontSize: '6px',
                                        fontFamily: 'Montserrat',
                                    },
                                    value: {
                                        fontFamily: 'Montserrat',
                                        formatter(val, opts) {
                                            return `${kFormatter(val)}`
                                        },
                                    },
                                    total: {
                                        show: true,
                                        fontFamily: 'Montserrat',
                                        label: 'Global',
                                        formatter(opts) {
                                            const global = opts.globals.seriesTotals.reduce((carry, i) => {
                                                return carry + i
                                            }, 0)
                                            return kFormatter(global)
                                        },
                                    },
                                },
                            },
                        },
                    },
                    labels: [],
                },
            },
            isShowChart: true,
        }
    },
    computed: {
        isLoadDashboard(){
            return this.findSetting('ACTIVAR_CARGA_DASHBOARD') || false
        }
    },
    created() {

    },
    beforeDestroy() {
        // window.removeEventListener('resize', this.updateChartData);
    },
    async mounted() {
        if (this.isLoadDashboard) {
            await this.getSalesArticlesGroups()
        }
        // window.addEventListener('resize', this.updateChartData);
    },
    methods: {
        kFormatter,
        changeView() {
            this.isShowChart = !this.isShowChart

            // Forzar la actualización cuando se muestra el gráfico
            if (this.isShowChart) {
                this.$nextTick(() => {
                    this.updateChartData();  // Asegúrate de actualizar el gráfico cuando se vuelva a mostrar
                });
            }
        },
        updateChartData() {
            this.$nextTick(() => {

                this.donutChart.chartOptions.labels = []
                this.donutChart.series = []

                if (this.groups.length > 0) {

                    this.donutChart.chartOptions.labels = this.groups.map(item => item.grupo).flat();
                    this.donutChart.series = this.groups.map(item => item.venta_Importe).flat();

                    // Forzar la actualización del gráfico
                    this.$refs.donutChart.updateSeries(this.donutChart.series);
                    this.$refs.donutChart.updateOptions(this.donutChart.chartOptions, true, true, true);
                }
            })
        },
        async getSalesArticlesGroups() {
            this.groups = []
            // const dates = this.isLastYear ? this.filterByDates('current_month_last_year') : this.filterByDates(this.filter.key)
            // this.selectedMonth = moment(dates.fechaIni).format('MMMM')

            const myDates = this.filterByDates(this.filter.key)
            if (this.isLastYear) {
                myDates.fechaIni = moment(myDates.fechaIni).subtract(1, 'year').format('YYYY-MM-DD')
                myDates.fechaFin = moment(myDates.fechaFin).subtract(1, 'year').format('YYYY-MM-DD')
            }
            this.selectedMonth = moment(myDates.fechaIni).format('MMMM')

            const query = {
                ...myDates,
                vendedorId: this.filter.vendedor_Id,
                esDevolucion: true,
            }

            if (this.filter && this.filter.key) {
                this.loading = true
                const { data } = await DashboardsResource.getSalesArticlesGroups(query)
                this.loading = false
                if (data.isSuccesful && data.data != null && data.data.length > 0) {
                    this.groups = data.data
                    this.$nextTick(() => {
                        this.updateChartData()
                    })
                }
            }
        }
    },
    watch: {
        refresh(value) {
            if (value) {
                this.getSalesArticlesGroups()
            }
        },
        filter(value) {
            this.getSalesArticlesGroups()
        },
        isLastYear(value) {
            this.getSalesArticlesGroups()
        }
    },
    setup(props, { emit }) {
        
        const selectedMonth = ref(null)
        const lastYear = ref(null)
        const currentYear = ref(null)
        const isLastYear = ref(false)
       
        const filter = computed(() => {
            const _filter = store.getters['auth/getFilterOnDashboardSeller']
            const seller_id = store.getters['auth/getSellerId']
            return {
                ..._filter,
                vendedor_Id: seller_id
            }
        })

        currentYear.value = filter.value.label.match(/\d+/g).map(Number)[0]
        lastYear.value = currentYear.value - 1

        const setLastYear = () => {
            isLastYear.value = !isLastYear.value
        }

        watch(() => filter.value, (value) => {
            currentYear.value = value.label.match(/\d+/g).map(Number)[0]
            lastYear.value = currentYear.value - 1
        })


        return {
            selectedMonth,
            isLastYear,
            lastYear,
            currentYear,

            filter,

            setLastYear,
        }
    },
}
</script>
