import axios from '@/libs/axios'

export default class Exports {

    ExportDoctosPvDescuentos (query) {
        const url = `/v2/Exportar/ExportDoctosPvDescuentos`
        return axios.get(url, {
            responseType: 'blob',
            params: query
        })
    }    
}