<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body>
            <b-card-header>
                <b-card-title>{{ title }}</b-card-title>
                <b-card-sub-title>
                    
                    <!-- <b-form-select size="sm" v-model="by_year" :options="years" :clearable="false"/> -->
                    <!-- <b-form-group label="Desde" label-for="date_start">
                        <b-form-select id="date_start" size="sm" v-model="by_year" :options="years" :clearable="false"/>
                    </b-form-group>  -->
                </b-card-sub-title>
            </b-card-header>
            
            <b-card-body v-if="!hiddenFilters">
                <b-row>
                    <b-col cols="12" md="4">
                        <v-select :disabled="isInputDisabled" class="select-size-sm" v-model="by_expired" :options="filterDates"
                            :reduce="option => option.value" :clearable="false" style="min-width: 150px!important;"/>
                    </b-col>
                    <b-col cols="12" md="5">
                        <v-select :disabled="isSeller || isInputDisabled" class="select-size-sm" v-model="vendedor_Id" :options="sellers" :reduce="option => option.vendedor_Id" label="nombre"
                            :clearable="true" placeholder="Selecciona un vendedor" />
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-button size="sm" variant="primary" @click="getCustomerCharges" class="mr-1">Buscar</b-button>
                        <b-button @click="resetSearch" size="sm" variant="outline-primary">Limpiar</b-button>
                    </b-col>
                </b-row>    
                <b-row>
                    <b-col cols="12" :md="9" :class="'mt-1'">
                        <b-input-group>
                            <b-form-input :readonly="customerName != ''" size="sm" v-model="customerName" placeholder="Buscar cliente"
                                @click="activeSearchCustomer = true" autocomplete="off" />
                            <b-input-group-append>
                                <b-button size="sm" variant="outline-primary" @click="activeSearchCustomer = true">
                                    <feather-icon icon="SearchIcon" />
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>

                        <!-- Search Customers -->
                        <search-customers :active="activeSearchCustomer" @selected="selectedCustomer"
                            @close="activeSearchCustomer = false" />
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-button v-if="items.length > 0" size="sm" variant="outline-dark" @click="exportToExcel" class="mr-1 mt-1">Descargar</b-button>
                    </b-col>
                </b-row>            
            </b-card-body>

            
            <div ref="tableContainer" class="table-responsive" :class="`${items.length > 0 ? 'b-table-sticky-header' : ''}`">
                <table class="table table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small">
                    <thead>
                        <tr>
                            <th class="text-center">Cliente</th>
                            <th class="text-center">Fecha Fact.</th>
                            <th class="text-center">Folio</th>
                            <th class="text-center">Vencimiento</th>
                            <th class="text-center">Días atraso</th>
                            <th class="text-center">Saldo</th>
                        </tr>
                    </thead>
                    <tbody v-for="(items, index) in charges" :key="index">
                        <tr>
                            <th colspan="5">{{ index }} - SALDO: {{ formatCurrency(charges[index].reduce((acc, item) => acc + item.saldo_Cargo, 0)) }}</th>
                            <th>
                                <b-button @click="getStatementAccountPDF(items, index)" size="sm" variant="outline-primary">
                                    <feather-icon icon="PrinterIcon" size="16" />
                                    Edo. Cuenta
                                </b-button>
                            </th>
                        </tr>
                        <tr v-for="(item, index) in items" :key="index">
                            <td></td>
                            <td>{{ formatDate(item.fecha) }}</td>
                            <td>{{ item.folio }}</td>
                            <td>{{ formatDate(item.fecha_Vencimiento) }}</td>
                            <td class="text-center">
                                <b-badge v-if="item.atraso > 0" variant="danger">{{ item.atraso }}</b-badge>
                                <b-badge v-else variant="warning">Por Vencer</b-badge>
                            </td>
                            <td>{{ formatCurrency(item.saldo_Cargo) }}</td>
                        </tr>
                        <tr>
                            <th colspan="5" class="text-right">TOTAL</th>
                            <th>
                                {{ formatCurrency(charges[index].reduce((acc, item) => acc + item.saldo_Cargo, 0)) }}
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>

            <b-card-body v-if="items.length > 0" class="invoice-padding pb-0">
                <b-row>
                    <b-col cols="12" md="6" class="mt-md-0 mt-3 d-flex align-items-center" order="2" order-md="1">
                    </b-col>            
                    <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                        <div class="invoice-total-item">
                            <h5>GRAN TOTAL: {{ formatCurrency(items.reduce((acc, item) => acc + item.saldo_Cargo, 0)) }}</h5>
                        </div>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </b-overlay>
</template>

<script>
import {
    BRow, BCol, BBadge, BFormGroup,
    BCard, BCardText, BCardFooter, BCardSubTitle, BTable, BAvatar, BImg, BOverlay, BButton, BCardBody, BCardHeader, BCardTitle,
    BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormInput, BFormSelect, BFormCheckbox, BFormTextarea, BFormTags,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import * as XLSX from 'xlsx';
import moment from 'moment';

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SearchCustomers from '@core/components/customers/SearchCustomers.vue';
import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

import ReportsProvider from '@/providers/Reports';
const ReportsResource = new ReportsProvider()

export default {
    name: 'WareHouseTable',
    components: {
        BRow, BCol,
        BCardText,
        BCard,
        BCardFooter,
        BCardSubTitle,
        BTable,
        BAvatar,
        BImg,
        BOverlay,
        BButton,
        BCardBody,
        BCardHeader,
        BCardTitle,
        BCardActions,
        BBadge,
        BFormGroup,
        BInputGroup, 
        BInputGroupAppend, 
        BInputGroupPrepend, 
        BFormInput, 
        BFormSelect, 
        BFormCheckbox, 
        BFormTextarea, 
        BFormTags,

        //
        vSelect,
        SearchCustomers,
    },
    directives: {
        Ripple,
    },
    props: {
        sellers: {
            type: Array,
            default: () => []
        },
        hiddenFilters: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Facturas Clientes'
        },
        delay: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            loading: false,
            filterDates: [
                { value: 0, label: 'Todas'},
                { value: 1, label: 'Por vencer'},
                { value: 2, label: 'Vencidas de 1 a 30 días'},
                { value: 3, label: 'Vencidas de 31 a 60 días'},
                { value: 4, label: 'Vencidas de 61 a 90 días'},
                { value: 5, label: 'Vencidas de 91 días a un año'},
                { value: 6, label: 'Vencidas de un año en adelante'},
            ],
            by_year: new Date().getFullYear(),
            by_expired: 1,
            charges: [],
            items:[],
            activeSearchCustomer: false,
            customer_id: null,
            customerName: '',
            years: [],
            vendedor_Id: 0,
            isInputDisabled: false,
            querySearch: {}            
        }
    },
    computed: {
        isAdministrator() {
			return store.getters['auth/getUser'] ? 
            (store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol'] ||
            store.getters['auth/getUser'].customRol.id == store.getters['auth/getRolDirector']) : false
		},
        isSeller (){
            return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getIsRoleSeller'] : false
        }
    },
    created() {
        this.generateYears()
    },
    async mounted() {
        // await this.getCustomerCharges()
        if (this.isSeller) {
            this.vendedor_Id = store.getters['auth/getSellerId']
        }
    },
    methods: {
        kFormatter,
        exportToExcel() {
            const table = this.$refs.tableContainer;
            const wb = XLSX.utils.table_to_book(table);
            const until = moment().format('YYYY-MM-DD')
            XLSX.writeFile(wb, `Cargos-Clientes-${until}.xlsx`);
        },
        generateYears() {
            const currentYear = new Date().getFullYear();
            // Crear un array con los últimos 3 años + el año actual
            this.years = Array.from({ length: 26 }, (_, i) => currentYear - i);
        },
        resetSearch() {
            this.by_expired = 1
            this.customer_id = null
            this.customerName = ''
            this.items = []
            this.charges = []
            this.isInputDisabled = false

            if (!this.isSeller) {
                this.vendedor_Id = 0
            }
        },
        async selectedCustomer(customer) {
            if (!customer) return
            this.activeSearchCustomer = false
            this.customer_id = customer.cliente_Id
            this.customerName = customer.nombre

            //Como es busqueda por cliente, se resetea el filtro de vendedor y el filtro de atraso
            this.vendedor_Id = 0
            this.by_expired = 0
            this.by_year = 2000
            this.isInputDisabled = true

            //Generar la consulta solo de ese cliente.
            await this.getCustomerCharges()



        },
        async getStatementAccountPDF(items, index) {           

            const clienteId = items[0].cliente_Id
            const fileName = this.generateFileName(items[0].nombre_Cliente);
            
            const query = {
                clienteId: clienteId,
                // atraso: this.by_expired,
                vendedorId: this.isAdministrator ? this.vendedor_Id : store.getters['auth/getSellerId'],
                fechaIni: `${this.by_year}-01-01`,
                fechaFin: `${this.by_year}-12-31`,
            }

            try {
                this.loading = true
                const { data } = await ReportsResource.getStatementAccountPDF(query)
                this.loading = false                

                const blob = new Blob([data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${fileName}.pdf`;
                link.click();
            }catch(e){
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async getCustomerCharges() {
            this.items = []
            this.charges = []

            const query = {
                atraso: this.delay ? this.delay : this.by_expired,
                vendedorId: this.isAdministrator ? this.vendedor_Id : store.getters['auth/getSellerId'],
                fechaIni: `${this.by_year}-01-01`,
                fechaFin: moment().endOf('year').format('YYYY-MM-DD') //`${this.by_year}-12-31`,
            }

            if (this.customer_id) {
                query.clienteId = this.customer_id
            }

            this.loading = true

            try {
                const { data } = await DashboardsResource.getCustomerCharges(query)
                if (data.isSuccesful) {
                    if (data.data.length == 0) {
                        this.danger('No se encontraron facturas')
                    } else {
                        this.items = data.data
                        this.charges = _.groupBy(data.data, 'nombre_Cliente')
                        this.by_year = new Date().getFullYear()
                    }
                } else {
                    this.danger(data.message)
                }
            } catch (error) {
                this.handleResponseErrors(error)
            } finally {
                this.loading = false
            }
        }
    },
    watch: {
        delay(value) {
            console.log('delay', value)
            this.by_expired = value
            this.getCustomerCharges()
        },
    }
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
