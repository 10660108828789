<template>
	<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
		<b-card no-body>
			<b-card-header>
				<div>
                    <b-card-title>{{ isShowChart ? 'Ventas Por Zona Clientes' : 'Detalle Ventas Por Zona Clientes' }}</b-card-title>
                    <b-card-text class="font-small-2 text-capitalize">
                        {{ selectedMonth }} {{ isLastYear ? lastYear : currentYear }}
                    </b-card-text>
                </div>

                <b-dropdown variant="link" no-caret class="chart-dropdown" toggle-class="p-0">
                    <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="22" class="text-body cursor-pointer"/>
                    </template>
                    <b-dropdown-item href="#" @click="changeView">
                        <span v-text="isShowChart ? ' Ver detalles' : ' Ver gráfica'" />
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click="setLastYear">
                       Ver Año {{ isLastYear ? currentYear : lastYear }}
                    </b-dropdown-item>
                </b-dropdown>
			</b-card-header>

			<div v-show="sales.length > 0">
				<vue-apex-charts v-if="isShowChart && !loading && sales.length > 0" ref="donutChart" type="pie"
					:options="chartOptions" :series="series" />
				<div v-if="!isShowChart" ref="tableContainer" class="table-responsive b-table-sticky-header">
					<table 
						class="table table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small">
						<thead>
							<tr>
								<th>Zona</th>
								<th>Importe</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in sales" :key="index">
								<td style="font-size: 10px;">{{ item.zona }}</td>
								<td style="font-size: 10px;">{{ formatCurrency(item.venta_Importe) }}</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td class="font-weight-bold text-right">TOTAL</td>
								<td class="font-weight-bold">{{ formatCurrency(sales.reduce((carry, item) => carry + item.venta_Importe, 0)) }}</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
			<div v-if="sales.length <= 0">
                <h5 class="text-center">No hay datos para mostrar para el año {{ isLastYear ? lastYear : currentYear }}</h5>
            </div>
		</b-card>
	</b-overlay>
</template>

<script>
import moment from 'moment'
import {
    BCard, BCardTitle, BImg, BAvatar, BCardText, BCardHeader, BOverlay, BButton,
    BDropdown, BDropdownItem, BCardFooter, BCardBody, BRow, BCol
} from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import { kFormatter } from '@core/utils/filter'
import FeatherIcon from '../feather-icon/FeatherIcon.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import store from '@/store';
import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()


export default {
	components: {
		BCard,
		BCardTitle,
        BCardHeader,
        BAvatar,
        BImg,
        BCardText,
        BOverlay,
        BButton,
        BDropdown,
        BDropdownItem,
        BCardFooter,
        BCardBody,
        BRow,
        BCol,
		VueApexCharts,
	},
	props: {
		refresh: {
            type: Boolean,
            default: () => false,
            required: true,
        },
	},
	data() {
		return {
			loading: false,
			sales: [],
			series: [],
			chartOptions: {
				chart: {
					type: 'donut',
				},
				legend: {
					show: true,
					position: 'bottom',
					fontFamily: 'Montserrat',
				},
				plotOptions: {
					pie: {
						startAngle: -90,
						endAngle: 90,
						offsetY: 10
					}
				},
				grid: {
					// padding: {
					// 	bottom: 10
					// }
				},
				labels: [],
				// responsive: [{
				// 	breakpoint: 480,
				// 	options: {
				// 		chart: {
				// 			width: 200
				// 		},
				// 		legend: {
				// 			position: 'bottom'
				// 		}
				// 	}
				// }]
			},

			isShowChart: true,
		}
	},
	computed: {
        isLoadDashboard(){
            return this.findSetting('ACTIVAR_CARGA_DASHBOARD') || false
        }
    },
	beforeDestroy() {
		window.removeEventListener('resize', this.updateChartData);
	},
	async mounted() {
		if(this.isLoadDashboard) {
			await this.getSalesCustomerZones()
			window.addEventListener('resize', this.updateChartData);
		}
	},
	methods: {
		kFormatter,
		changeView() {
			this.isShowChart = !this.isShowChart
			if (this.isShowChart) {
				// Asegurar que el gráfico se redimensiona al volver a ser visible
				this.$nextTick(() => {
					this.updateChartData()
				});
			}
		},
		updateChartData() {
			this.$nextTick(() => {

				this.chartOptions.labels = []
				this.series = []

				this.chartOptions.labels = this.sales.map(item => item.zona).flat();
				this.series = this.sales.map(item => item.venta_Importe).flat();

				//Forzar la actualización del gráfico
				// if (this.sales.length > 0 && this.series.length > 0) {
					// this.$refs.donutChart.updateSeries(this.series, true);
					this.$refs.donutChart.updateOptions(this.chartOptions, true, true, true);
				// }
			})
		},
		async getSalesCustomerZones() {
			this.sales = []
			const myDates = this.filterByDates(this.filter.key)
            if (this.isLastYear) {
                myDates.fechaIni = moment(myDates.fechaIni).subtract(1, 'year').format('YYYY-MM-DD')
                myDates.fechaFin = moment(myDates.fechaFin).subtract(1, 'year').format('YYYY-MM-DD')
            }
            this.selectedMonth = moment(myDates.fechaIni).format('MMMM')

            const query = {
                ...myDates,
                vendedorId: this.filter.vendedor_Id,
            }

			if (this.filter && this.filter.key) {
				this.loading = true
				const { data } = await DashboardsResource.getSalesCustomerZones(query)
				this.loading = false
				
				if (data.isSuccesful && data.data != null && data.data.length > 0) {
					this.sales = data.data //_.orderBy(data.data, ['zona', 'asc'])
					this.$nextTick(() => {
						this.updateChartData()
					})
				}
			}
		}
	},
	watch: {
		refresh(value) {
			if(value) {
				this.getSalesCustomerZones()
			}	
		},
		filter(value) {
			this.getSalesCustomerZones()
		},
		isLastYear(value) {
			this.getSalesCustomerZones()
		}
	},
	setup(props, { emit }) {
        
        const selectedMonth = ref(null)
        const lastYear = ref(null)
        const currentYear = ref(null)
        const isLastYear = ref(false)
       
        const filter = computed(() => {
            const _filter = store.getters['auth/getFilterOnDashboardSeller']
            const seller_id = store.getters['auth/getSellerId']
            return {
                ..._filter,
                vendedor_Id: seller_id
            }
        })

		currentYear.value = filter.value.label.match(/\d+/g).map(Number)[0]
        lastYear.value = currentYear.value - 1

        const setLastYear = () => {
            isLastYear.value = !isLastYear.value
        }

        watch(() => filter.value, (value) => {
            currentYear.value = value.label.match(/\d+/g).map(Number)[0]
            lastYear.value = currentYear.value - 1
        })


        return {
            selectedMonth,
            isLastYear,
            lastYear,
            currentYear,

            filter,

            setLastYear,
        }
    },
}
</script>